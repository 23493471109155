<template>
  <div class="ml-5 mr-5">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      loader="dots"
      color="#007bff"
      :height="45"
      :width="75"
      :is-full-page="fullPage"
    ></loading>
    <div class="text-right">
      <template>
        <v-dialog v-model="toggleAddSectorModal" persistent max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              Add
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Sector</span>
            </v-card-title>
            <template>
              <v-form
                ref="addSector"
                v-model="validateAddSector"
                lazy-validation
              >
                <v-container class="grey lighten-5">
                  <v-select
                    :items="sectors"
                    outlined
                    dense
                    :label="SectorLabel"
                    v-model="watchsectors"
                  />
                  <v-select
                    :items="all_category"
                    outlined
                    dense
                    :label="CategoryLabel"
                    v-model="watchcategory"
                    v-show="showCategory"
                  />
                  <v-select
                    :items="all_sub_category"
                    outlined
                    dense
                    :label="SubCategoryLabel"
                    v-model="watchsubcategory"
                    v-show="showSubCategory"
                  />
                  <v-select
                    :items="all_sub_sub_category"
                    outlined
                    dense
                    :label="SubSubCategoryLabel"
                    v-model="watchsubsubcategory"
                    v-show="showSubSubCategory"
                  />
                  <v-text-field
                    v-model="new_sector_name"
                    v-show="showAddSectorName"
                    color="purple darken-2"
                    :label="addSectorlabel"
                    outlined
                    dense
                    :rules="nameRules"
                    required
                  ></v-text-field>
                     <v-checkbox
                     class="my-0"
    fluid
      v-model="new_work_site_voluntary"
      :label="`Worksite/Voluntary`"
    ></v-checkbox>
     <v-checkbox
      class="my-0"
      v-model="new_sector_search_active"
      :label="`Power Search`"
    ></v-checkbox>
                  <!-- <v-btn color="blue darken-1" type="submit"> Submit </v-btn> -->
                  <v-btn
                    :disabled="!validateAddSector"
                    class="confirm_button"
                    @click="addNewSector"
                  >
                    Add Sector
                  </v-btn>
                  <v-btn
                    class="close_button mx-2"
                    text
                    @click="resetAddSectorForm()"
                  >
                    Close
                  </v-btn>
                </v-container>
              </v-form>
            </template>
          </v-card>
        </v-dialog>
      </template>
    </div>
    <v-dialog v-model="toggleSectorUpdateModel" persistent max-width="600px">
      <v-card>
        <v-form ref="form" v-model="validUpdateSector" lazy-validation>
          <v-card-title>
            <span class="headline">Update Sector</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Sector Name*"
                    v-model="updateSector.name"
                    :rules="nameRules"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row style="margin-top: -43px;">
                <v-col cols="12" sm="12"  class="my-0">
                  <v-checkbox

    fluid
      v-model="updateSector.work_site_voluntary"
      :label="`Worksite/Voluntary`"
    ></v-checkbox>
                </v-col>
              </v-row>
              <v-row  class="my-0"
    fluid style="margin-top: -43px !important;">
                <v-col cols="12" sm="12" >
                   <v-checkbox

      v-model="updateSector.sector_search_active"
      :label="`Power Search`"
    ></v-checkbox>
                </v-col>
              </v-row>
            </v-container>
            <small width="25" height="25">*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn class="confirm_button mx-2" text @click="updateSectorName">
              Save
            </v-btn>
             <v-btn
              class="close_button mx-2"
              text
              @click="toggleSectorUpdateModel = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-row no-gutters>
      <v-col cols="10" sm="10" md="10">
        <v-row
          no-gutters
          v-for="(sector, sectorIndex) in all_sectors"
          :key="sector.id"
        >
          <v-col cols="10" sm="10" md="10" class="tree">
            <nav class="tree-nav">
              <div v-if="sector.children.length">
                <details class="tree-nav__item is-expandable">
                  <summary class="tree-nav__item-title">
                    <span>{{ sector.name }}</span>
                    <span v-if="sector.children.length">
                      ({{ sector.children.length }})
                    </span>


                    <v-icon
                      medium
                      color="grey"
                      @click="
                        openSectorUpdateModal(
                          sector.id,
                          1,
                          sector.id,
                          sector.name,
                          sectorIndex,
                          sector.sector_search_active,
                          sector.work_site_voluntary
                        )
                      "
                      v-show="checkIfOperationExistForModule('edit')"
                    >
                      mdi-pencil
                    </v-icon>
                  </summary>
                  <div
                    v-for="(category, catIndex) in sector.children"
                    :key="category.id"
                  >
                    <!-- if category has children -->
                    <details
                      class="tree-nav__item is-expandable"
                      v-if="category.children.length"
                    >
                      <summary class="tree-nav__item-title">
                        <span>{{ category.name }}</span>
                        <span v-if="category.children.length">
                          ({{ category.children.length }})</span
                        >
                        <v-icon
                          medium
                          color="grey"
                          @click="
                            openSectorUpdateModal(
                              sector.id,
                              2,
                              category.id,
                              category.name,
                              catIndex,category.sector_search_active,
                          category.work_site_voluntary

                            )
                          "
                          v-show="checkIfOperationExistForModule('edit')"
                        >
                          mdi-pencil
                        </v-icon>
                      </summary>
                      <div
                        v-for="(
                          subcategory, subCatIndex
                        ) in category.children"
                        :key="subcategory.id"
                      >
                        <!-- if subcat has children -->
                        <details
                          class="tree-nav__item is-expandable"
                          v-if="subcategory.children.length"
                        >
                          <summary class="tree-nav__item-title">
                            <span>{{ subcategory.name }}</span>
                            <span v-if="subcategory.children.length">
                              ({{ subcategory.children.length }})</span
                            >


                            <v-icon
                              medium
                              color="grey"
                              @click="
                                openSectorUpdateModal(
                                  sector.id,
                                  3,
                                  subcategory.id,
                                  subcategory.name,
                                  subCatIndex,subcategory.sector_search_active,
                          subcategory.work_site_voluntary
                                )
                              "
                              v-show="checkIfOperationExistForModule('edit')"
                            >
                              mdi-pencil
                            </v-icon>
                          </summary>
                          <div class="tree-nav__item">
                            <a
                              class="tree-nav__item-title"
                              v-for="(
                                subSubCategory, subSubIndex
                              ) in subcategory.children"
                              :key="subSubCategory.id"
                              ><i class="icon ion-ios-bookmarks"></i>
                              {{ subSubCategory.name }}

                              <v-icon
                                medium
                                color="grey"
                                @click="
                                  openSectorUpdateModal(
                                    sector.id,
                                    4,
                                    subSubCategory.id,
                                    subSubCategory.name,
                                    subSubIndex,subSubCategory.sector_search_active,
                          subSubCategory.work_site_voluntary
                                  )
                                "
                                v-show="checkIfOperationExistForModule('edit')"
                              >
                                mdi-pencil
                              </v-icon>

                              <v-icon
                                medium
                                color="grey"
                                @click="deleteSector(subSubCategory.id)"
                                v-show="checkIfOperationExistForModule('delete')"
                              >
                                mdi-delete
                              </v-icon>
                            </a>
                          </div>
                        </details>

                        <!-- if subcat has no children -->
                        <div v-else>
                          <a class="tree-nav__item-title"
                            ><i class="icon ion-ios-bookmarks"></i>
                            {{ subcategory.name }}

                            <v-icon
                              medium
                              color="grey"
                              @click="
                                openSectorUpdateModal(
                                  sector.id,
                                  3,
                                  subcategory.id,
                                  subcategory.name,
                                  subCatIndex,subcategory.sector_search_active,
                          subcategory.work_site_voluntary
                                )
                              "
                              v-show="checkIfOperationExistForModule('edit')"
                            >
                              mdi-pencil
                            </v-icon>

                            <v-icon
                              medium
                              color="grey"
                              @click="deleteSector(subcategory.id)"
                              v-show="checkIfOperationExistForModule('delete')"
                            >
                              mdi-delete
                            </v-icon>
                          </a>
                        </div>
                      </div>
                      <!-- single subcategory -->
                    </details>
                    <!-- else category has no child -->
                    <div v-else>
                      <a class="tree-nav__item-title"
                        ><i class="icon ion-ios-bookmarks"></i>
                        {{ category.name }}

                        <v-icon
                          medium
                          color="grey"
                          @click="
                            openSectorUpdateModal(
                              sector.id,
                              2,
                              category.id,
                              category.name,
                              catIndex,category.sector_search_active,
                          category.work_site_voluntary
                            )
                          "
                          v-show="checkIfOperationExistForModule('edit')"
                        >
                          mdi-pencil
                        </v-icon>

                        <v-icon
                          medium
                          color="grey"
                          @click="deleteSector(category.id)"
                          v-show="checkIfOperationExistForModule('delete')"
                        >
                          mdi-delete
                        </v-icon>
                      </a>
                    </div>
                  </div>
                </details>
              </div>
              <!-- sector has no children -->
              <div v-else>
                <a class="tree-nav__item-title"
                  ><i class="icon ion-ios-bookmarks"></i>
                  {{ sector.name }}

                  <v-icon
                    medium
                    color="grey"
                    @click="
                      openSectorUpdateModal(
                        sector.id,
                        1,
                        sector.id,
                        sector.name,
                        sectorIndex,
                        sector.sector_search_active,
                          sector.work_site_voluntary
                      )
                    "
                    v-show="checkIfOperationExistForModule('edit')"
                  >
                    mdi-pencil
                  </v-icon>
                  <v-icon medium color="grey" @click="deleteSector(sector.id)" v-show="checkIfOperationExistForModule('delete')">
                    mdi-delete
                  </v-icon>
                </a>
              </div>
            </nav>
          </v-col>
          <!-- <v-btn class="mx-2" fab dark small
                      width="25"
                      height="25" color="cyan">
            <v-icon small dark> mdi-pencil </v-icon>
          </v-btn> -->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading },
  data() {
    return {
      checkbox: false,
      isLoading: true,
      fullPage: true,
      //add form
      toggleAddSectorModal: false,
      validateAddSector: false,
      watchsectors: "new",
      watchcategory: "new",
      watchsubcategory: "new",
      watchsubsubcategory: "new",
      parent_id: 0,
      new_sector_name: "",
      new_work_site_voluntary:0,
      new_sector_search_active:0,
      SectorLabel: "Sector",
      CategoryLabel: "Category",
      SubCategoryLabel: "Sub-Category",
      SubSubCategoryLabel: "Sub Sub Category",
      addSectorlabel: "Sector Name",
      showCategory: false,
      showSubCategory: false,
      showSubSubCategory: false,
      showAddSectorName: true,
      sectors: [],
      all_category: [],
      all_sub_category: [],
      all_sub_sub_category: [],
      //update sector form
      toggleSectorUpdateModel: false,
      validUpdateSector: false,
      work_site_voluntary:0,
      updateSector: {
        id: null,
        name: "",
        sectorId: null,
        sectorType: "",
        index: "",
        work_site_voluntary:0,
        sector_search_active:0
      },
      nameRules: [(v) => !!v || "Name is required"],
      all_sectors: [],
    };
  },
  mounted: function () {
    // this.get_all_sectors();
    // this.get_sectors(0, "sector");
  },
  methods: {
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    resetAddSectorForm() {
      this.watchsectors = "new";
      this.watchcategory = "new";
      this.watchsubcategory = "new";
      this.showSubSubCategory = "new";
      this.new_sector_name = "";
      this.new_work_site_voluntary ="",
      this.new_sector_search_active ="",
      this.all_category = [];
      this.all_sub_category = [];
      this.all_sub_sub_category = [];
      this.showCategory = false;
      this.showSubCategory = false;
      this.showSubSubCategory = false;
      this.toggleAddSectorModal = false;
      this.addSectorlabel = "Sector Name";
    },
    async get_all_sectors() {
      let view = this;
      view.isLoading = true;
      await this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view }).get(`${view.$url("ALLSECTORS")}?type=sector`).then((res) => {
        console.log(res);
        view.isLoading = false;
        view.all_sectors = res.data.payload;
        this.get_sectors(0, "sector")
      });
    },
    get_sectors(sector_id, sector_type) {
      let view = this;
      view.isLoading = true;
      let url = view.$url("GETSECTORS");
      //let url = 'http://0.0.0.0:5011/get-sectors'
      if (sector_id != 0) {
        url = `${view.$url("GETSECTORS")}?id=${sector_id}`;
        //url = `http://0.0.0.0:5011/get-sectors?id=${sector_id}`;
      }
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view }).get(url).then((res) => {
        console.log(res);
        view.isLoading = false;
        let sectors = res.data.payload;
        let temp_arr = [];
        sectors.unshift({ text: "New", value: "new" });
        switch (sector_type) {
          case "sector":
            view.sectors = sectors;
            this.addSectorlabel = "Sector Name";
            break;
          case "category":
            view.all_category = sectors;
            this.addSectorlabel = "Category Name";
            break;
          case "subcategory":
            view.all_sub_category = sectors;
            this.addSectorlabel = "Sub Category Name";
            break;
          case "subsubcategory":
            view.all_sub_sub_category = sectors;
            this.addSectorlabel = "Sub Sub Category Name";
            break;
          default:
            break;
        }
      });
    },
    openSectorUpdateModal(sectorId, sectorType, value, name, index,sector_search_active,work_site_voluntary) {
      console.log(work_site_voluntary)
      this.toggleSectorUpdateModel = !this.toggleSectorUpdateModel;
      this.updateSector.id = value;
      this.updateSector.name = name;
      this.updateSector.sectorId = sectorId;
      this.updateSector.sectorType = sectorType;
      this.updateSector.index = index;
      this.updateSector.sector_search_active = sector_search_active;
      this.updateSector.work_site_voluntary = work_site_voluntary ;
    },
    deleteSector(sector_id) {
      let view = this;
      console.log(sector_id);
      view.$swal
        .fire({
          title: "Are you sure ?",
          text: "This will delete this sector",
          type: "warning",
          showCancelButton: true,
          confirmButtonColor: "#DD6B55",
          confirmButtonText: "Yes, delete it !",
          cancelButtonText: "No, cancel !",
          closeOnConfirm: false,
          closeOnCancel: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: view })
              .delete(view.$url("ALLSECTORS"), {
                params: { sector_id: sector_id },
              })
              .then((res) => {
                view.isLoading = false;
                if (res.data.statusCode == 200) {
                  view.addSectorlabel = "Sector Name";
                  view.$swal.fire({
                    icon: "success",
                    title: "Success",
                    text: res.data.payload.message,
                  });
                }
                //this.all_sectors = res.data.payload;
                view.get_all_sectors();
              })
              .catch((e) => {
                view.isLoading = false;
                view.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong",
                });
              });
          } else if (result.isDenied) {
            view.$swal.fire("Cancelled", "Sector was not deleted!", "error");
          }
        });
    },
    addNewSector() {
      let view = this;
      if (view.$refs.addSector.validate()) {
        view.isLoading = true;
        let data = {
          parent_id: this.parent_id,
          new_sector_name: this.new_sector_name,
          new_work_site_voluntary :this.new_work_site_voluntary == true ?1:0,
          new_sector_search_active :this.new_sector_search_active,

        };

        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
          .post(view.$url("GETSECTORS"), data)
          .then((res) => {
            view.isLoading = false;
            if (res.data.statusCode == 200) {
              view.addSectorlabel = "Sector Name";
              view.$swal.fire({
                icon: "success",
                title: "Success",
                text: res.data.payload.message,
              });
              view.resetAddSectorForm();
              view.get_all_sectors();
            }else{
              view.$swal.fire({
                icon: "error",
                title: "Error",
                text: res.data.payload.message,
              });
            }
          })
          .catch((e) => {
            view.isLoading = false;
            view.$swal.fire({
              icon: "error",
              title: "Error",
              text: "Something went wrong",
            });
          });
      }
    },
    updateSectorName() {
      let view = this;
      if (view.$refs.form.validate()) {
        let all_sectors_copy = view.all_sectors;
        console.log(view.updateSector)
        view.updateSector["work_site_voluntary"] = view.updateSector.work_site_voluntary

        all_sectors_copy.forEach((element, secIndex) => {
          if (element.id == view.updateSector.sectorId) {
            view.isLoading = true;
            this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: view })
              .post(view.$url("ALLSECTORS"), view.updateSector)
              .then((res) => {
                view.isLoading = false;
                console.log(res);
                this.all_sectors = res.data.payload;
                view.get_all_sectors();
              });
            switch (view.updateSector.sectorType) {
              case 1:
                element.name = view.updateSector.name;
                break;
              case 2:
                if (element.children.length) {
                  element.children.forEach(function (cat, catIndex) {
                    if (cat.id == view.updateSector.id) {
                      view.all_sectors[secIndex].children[catIndex].name =
                        view.updateSector.name;
                    }
                  });
                }
                break;
              case 3:
                if (element.children.length) {
                  element.children.forEach(function (cat, catIndex) {
                    //console.log(cat);
                    if (cat.children.length) {
                      cat.children.forEach(function (subcat, subCatIndex) {
                        //console.log(subcat.id, view.updateSector.id);
                        if (subcat.id == view.updateSector.id) {
                          view.all_sectors[secIndex].children[
                            catIndex
                          ].children[subCatIndex].name =
                            view.updateSector.name;
                        }
                      });
                    }
                  });
                }
                break;
              case 4:
                if (element.children.length) {
                  element.children.forEach(function (cat, catIndex) {
                    //console.log(cat);
                    if (cat.children.length) {
                      cat.children.forEach(function (subcat, subCatIndex) {
                        console.log(subcat, view.updateSector.id);
                        if (subcat.children.length) {
                          subcat.children.forEach(function (
                            subSubCat,
                            subSubCatIndex
                          ) {
                            if (subSubCat.id == view.updateSector.id) {
                              view.all_sectors[secIndex].children[
                                catIndex
                              ].children[subCatIndex].children[
                                subSubCatIndex
                              ].name = view.updateSector.name;
                            }
                          });
                        }
                      });
                    }
                  });
                }
                break;
              default:
                break;
            }
          }
        });
        this.all_sectors = all_sectors_copy;
        this.toggleSectorUpdateModel = !this.toggleSectorUpdateModel;
      }
    },
  },
  watch: {
     '$store.state.Rawdata.current_active_module': function(o) {
      if(o){
           this.get_all_sectors();
      }
    },
    watchsectors: function (o) {
      console.log(o);
      if (o != "new") {
        this.parent_id = o;
        this.watchcategory = "new";
        this.watchsubcategory = "new";
        this.watchsubsubcategory = "new";
        this.get_sectors(o, "category");
        this.showCategory = true;
        this.showSubCategory = false;
        this.showSubSubCategory = false;
      } else {
        this.parent_id = 0;
        this.showCategory = false;
        this.showSubCategory = false;
        this.showSubSubCategory = false;
        this.addSectorlabel = "Sector Name";
      }
    },
    watchcategory: function (o) {
      console.log(o);
      if (o != "new") {
        this.parent_id = o;
        this.watchsubcategory = "new";
        this.watchsubsubcategory = "new";
        this.get_sectors(o, "subcategory");
        this.showSubCategory = true;
        this.showSubSubCategory = false;
      } else {
        console.log(this.watchsectors);
        this.parent_id = this.watchsectors;
        this.showSubCategory = false;
        this.showSubSubCategory = false;
        this.addSectorlabel = "Category Name";
      }
    },
    watchsubcategory: function (o) {
      console.log(o);
      if (o != "new") {
        this.parent_id = o;
        this.watchsubsubcategory = "new";
        this.get_sectors(o, "subsubcategory");
        this.showSubSubCategory = true;
      } else {
        this.parent_id = this.watchcategory;
        this.showSubSubCategory = false;
        this.addSectorlabel = "Sub Category Name";
      }
    },
    watchsubsubcategory: function (o) {
      console.log(o);
      if (o != "new") {
        this.new_sector_name = "";
        this.showAddSectorName = false;
      } else {
        this.parent_id = this.watchsubcategory;
        this.showAddSectorName = true;
        this.addSectorlabel = "Sub Sub Category Name";
      }
    },
  },
  created() {
    //this.getAffinity(1)
  },
};
</script>
<style  scoped>
/*
 Only custom marker for summary/details
 For cross browser compatible styling hide Firefox's marker by setting summary { display: block }
 and Chrome and Safari's marker by setting ::-webkit-details-marker {display: none;}
*/
summary {
  display: block;
  cursor: pointer;
  outline: 0;
}

summary::-webkit-details-marker {
  display: none;
}

.tree-nav__item {
  display: block;
  white-space: nowrap;
  color: #ccc;
  position: relative;
}
.tree-nav__item.is-expandable::before {
  content: "";
  height: 100%;
  left: 0.8rem;
  position: absolute;
  top: 2.4rem;
  height: calc(100% - 2.4rem);
}
.tree-nav__item .tree-nav__item {
  margin-left: 2.4rem;
}
.tree-nav__item.is-expandable[open] > .tree-nav__item-title::before {
  font-family: "ionicons";
  transform: rotate(180deg);
  content: "-";
}
.tree-nav__item.is-expandable > .tree-nav__item-title {
  padding-left: 2.4rem;
}
.tree-nav__item.is-expandable > .tree-nav__item-title::before {
  position: absolute;
  will-change: transform;
  transition: transform 300ms ease;
  font-family: "ionicons";
  color: #000;
  font-size: 1.1rem;
  content: "+";
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title {
  cursor: pointer;
  display: block;
  outline: 0;
  color: #000;
  font-size: 1rem;
  line-height: 3.2rem;
}
.tree-nav__item-title .icon {
  display: inline;
  padding-left: 1.6rem;
  margin-right: 0.8rem;
  color: #666;
  font-size: 1.4rem;
  position: relative;
}
.tree-nav__item-title .icon::before {
  top: 0;
  position: absolute;
  left: 0;
  display: inline-block;
  width: 1.6rem;
  text-align: center;
}

.tree-nav__item-title::-webkit-details-marker {
  display: none;
}
</style>
